<template>
  <div class="lgc__form-w">
    <div class="lgc__form-c">
      <ValidationMessage v-show="loginState === false">
        E-mail e/ou senha inválidos
      </ValidationMessage>

      <InputText
        :vmodel.sync="email"
        field-label="E-mail"
        field-placeholder="Digite seu e-mail"
        :state="loginState"
      />

      <InputText
        input-i-d="password-input"
        :vmodel.sync="password"
        field-label="Senha"
        field-placeholder="Digite sua senha"
        :state="loginState"
        input="password"
      />

      <div class="lgc__checkbox-c">
        <Checkbox
          checkbox-text="Lembrar-me"
          :checkbox-value.sync="checkboxValue"
        />
        <span
          class="lgc__recover-password"
          @click="redirectToForgotPage"
          >Esqueceu sua senha?</span
        >
      </div>
    </div>

    <ButtonFullWidth
      class="btn-enter"
      :disabled="disabledButton"
      @click="login"
    >
      <div slot="text">Entrar</div>
    </ButtonFullWidth>
  </div>
</template>

<script>
  import ValidationMessage from '@/views/authentication/components/card-layout/components/validation-message';
  import InputText from '@/components/input-text';
  import ButtonFullWidth from '@/components/buttons/button-full-width';
  import Checkbox from '@/components/checkbox';
  import useJwt from '@/auth/mainJwt';

  import { mapActions } from 'vuex';

  export default {
    name: 'LoginForm',
    components: {
      ValidationMessage,
      InputText,
      ButtonFullWidth,
      Checkbox
    },
    data: () => ({
      prefix: '',
      email: '',
      password: '',
      loginState: null,
      checkboxValue: false,
      disabledButton: false
    }),
    mounted() {
      this.blockCopyAndPasteInPasswordInput();
      this.setEventListenerKeydown();
    },
    methods: {
      ...mapActions('userStore', ['setUser']),
      blockCopyAndPasteInPasswordInput() {
        const input = document.querySelector('#password-input');

        input.addEventListener('paste', function (e) {
          e.preventDefault();
        });

        input.addEventListener('copy', function (e) {
          e.preventDefault();
        });
      },
      validateFields() {
        const { email, password } = this;
        const validate = !email || !password || !this._validateEmail(email) ? false : null;

        this.loginState = validate;
        return validate === null;
      },
      async login() {
        const validation = this.validateFields();
        if (!validation) return;

        const tenant = this.email.split('@');
        const prefix = tenant[1].split('.')[0];

        this.disabledButton = true;
        this._showLoading();

        await useJwt
          .login({
            email: this.email,
            password: this.password,
            prefix: prefix,
            remember: this.checkboxValue
          })
          .then((response) => {
            const loginData = response.data;
            const userData = response.data.userData;
            const infoAccount = {
              guid: userData.guid,
              employee_id: userData.employee_id,
              first_name: userData.first_name,
              last_name: userData.last_name,
              job_role: userData.job_role,
              photo: userData.photo
            };

            this.setUser({
              ...userData,
              name: `${infoAccount.first_name} ${infoAccount.last_name}`,
              role: infoAccount.job_role,
              picture: infoAccount.photo
            });

            const domain = window.location.href.includes('indsiders.com');
            const multipleAccount = this.$route.name === 'multiple-account';

            localStorage.setItem('accessToken', response.data.accessToken);
            localStorage.setItem('userData', JSON.stringify(userData));
            localStorage.setItem('prefix', prefix);

            if (
              localStorage.getItem('loggedAccountsInfo') &&
              !JSON.parse(localStorage.getItem('loggedAccountsInfo')).find(
                (item) => item.guid == response.data.userData.guid
              )
            ) {
              let guidAtual = JSON.parse(localStorage.getItem('loggedAccountsInfo'));
              guidAtual.unshift(infoAccount);
              localStorage.setItem('loggedAccountsInfo', JSON.stringify(guidAtual));
            }

            !localStorage.getItem('loggedAccountsInfo') &&
              localStorage.setItem('loggedAccountsInfo', JSON.stringify([infoAccount]));

            this.$cookies.set(
              'userInfo',
              {
                ...loginData,
                currentSite: userData.sites[0],
                language: 'pt-BR'
              },
              '7D',
              null,
              domain ? 'indsiders.com' : null
            );

            if (multipleAccount) {
              window.location.href = this.$mySfcLink;
            } else {
              this.$router.push({ name: 'home', params: { prefix: prefix } }).catch(() => {});
            }
            this.disabledButton = false;
            !multipleAccount && this._hideLoading();
          })
          .catch(() => {
            this.disabledButton = false;
            this.loginState = false;
            this._hideLoading();
          });
      },
      redirectToForgotPage() {
        this.$router.push({ name: 'forgot' }).catch(() => {});
      },
      setEventListenerKeydown() {
        window.addEventListener('keydown', this.loginPerKeydown);
      },
      removeEventListenerKeydown() {
        window.removeEventListener('keydown', this.loginPerKeydown);
      },
      loginPerKeydown({ key }) {
        key === 'Enter' && this.login();
      }
    },
    destroyed() {
      this.removeEventListenerKeydown();
      this._hideLoading();
    }
  };
</script>

<style lang="scss" scoped>
  .lgc__form-w {
    width: 100%;
    border-top: 1px solid #eeeeee;

    .lgc__form-c {
      gap: 16px;
      margin: 16px 0 32px;

      display: flex;
      flex-direction: column;

      .lgc__checkbox-c {
        display: flex;
        justify-content: space-between;

        .lgc__recover-password {
          padding: 0px 14px;
          color: #974900;
          font-size: 12px;
          font-weight: 600;
          line-height: 20px;
          letter-spacing: 0.15px;
          user-select: none;
          cursor: pointer;
        }
      }
    }
  }

  @media (max-width: 485px) {
    .lgc__form-w {
      .lgc__form-c {
        .lgc__checkbox-c {
          .lgc__recover-password {
            padding: 4px 14px !important;
            font-size: 12px !important;
            line-height: 20px !important;
          }
        }
      }
    }
  }
</style>
